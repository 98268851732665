<!--

      This view displays the list of recent orders. It is reachable from within
      the profile tab.

 -->

<template>
  <Page title="Eigene Kategorie">
    <template v-slot:header>
      <div @click="deleteUserProductCategory()" v-if="userProductCategory">
        <Icon name="trashOutline" class="icon-25" />
      </div>
    </template>
    <div class="section-title">Name</div>
    <div class="white-box">
      <div class="secondary-text">Gib einen Namen ein, der deine Kategorie beschreibt, wie <strong>Meine Snacks</strong>, <strong>Frühstück</strong>, ...</div>
      <ion-input type="text" class="input-field main-text" debounce="500" @ionChange="editUserProductCategory($event)" :value="userProductCategoryName"></ion-input>
    </div>
    <div v-show="userProductCategory">
      <ProductSection 
        editOwnProductCategory="remove" 
        @clicked="editUserProductCategoryProduct($event)" 
        :section="{ title: 'Ausgewählte Produkte', page: userProductCategoryProductPage }" 
        v-if="userProductCategoryProductPage && userProductCategoryProductPage.results && userProductCategoryProductPage.results.length" 
      />
      <NewCard v-else icon="sadOutline">
        Du hast noch keine Produkte in deiner Kategorie. Gib unten einen Suchbegriff ein.
      </NewCard>
      <div class="section-title">Produkt hinzufügen</div>
      <ProductSearch editOwnProductCategory="add" @clicked="editUserProductCategoryProduct($event)" />
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import ProductCard from '@/components/cards/ProductCard'
import NewCard from '@/components/cards/NewCard'
import ProductSection from '@/components/shopping/ProductSection.vue'
import ProductSearch from '@/components/ProductSearch.vue'
import { IonInput } from '@ionic/vue'

export default {
  name: 'addowncategory',
  components: {
    Page,
    ProductCard,
    NewCard,
    ProductSection,
    ProductSearch,
    IonInput
  },
  props: ['category'],
  data () {
    return {
      userProductCategory: null,
      userProductCategoryProductPage: {
        count: 0,
        results: [],
        next: null,
        previous: null
      },
      isCreating: null
    }
  },
  computed: {
    userProductCategoryName () {
      if (this.userProductCategory) return this.userProductCategory.title
    },
  },
  methods: {
    // Dealing with the category itself and its name
    // 1. Does the category exist? If yes, rename it if the users changes the name.
    async editUserProductCategory (event) {
      if (this.userProductCategory) {
        // Case 1: The category already exists. Thus, update its name if necessary.
        const nameChanged = this.userProductCategory.title !== event.detail.value
        if (nameChanged) {
          this.$store.dispatch('updateUserProductCategory',
            {id: this.userProductCategory.category, name: event.detail.value}
          )
          const foundCategoryLocally = this.$store.state.allProductsSections.find(section => section.category === this.userProductCategory.category)
          if (foundCategoryLocally) foundCategoryLocally.title = event.detail.value
        }
      } else if (event.detail.value && !this.isCreating) {
        // Case 2: The category does not yet exist. Hence, create it.
        this.isCreating = true
        await this.createUserProductCategory(event.detail.value)
        this.isCreating = false
        // This can potentially lead to the issue that a letter is being dropped,
        // if the PUT request did not finish before the user entered the next letter
        // into the input field. Unprobable, due to the debounce.
      }
    },
    // 2. Or create a new category
    async createUserProductCategory (name) {
      const details = {
        url: this.$store.state.api.userproductcategories,
        method: 'POST',
        data: { name: name }
      }
      await this.$store.dispatch('apiSend', details).then(userProductCategory => {
        const newCategory = {
          category: userProductCategory.id,
          title: userProductCategory.name,
          page: {
            count: 0,
            results: [],
            next: null,
            previous: null
          },
          position: -3,
          isOwnProductCategory: true
        }
        this.userProductCategory = newCategory
        // Add the section in the All Products page:
        if (this.$store.state.allProductsSections) {
          this.$store.state.allProductsSections.push(newCategory)
          this.$store.state.allProductsSections.sort((a, b) => a.position - b.position)
          this.$store.dispatch('save', { key: 'allProductsSections', value: this.$store.state.allProductsSections })
        }
      })
    },
    // 3. If the user clicks on the delete button, delete the whole category.
    deleteUserProductCategory () {
      // Delete the category in the backend
      this.$store.dispatch('deleteUserProductCategory', this.userProductCategory.category)
      // Delete the category's section from the products tab
      if (this.$store.state.allProductsSections) {
        this.$store.state.allProductsSections = this.$store.state.allProductsSections.filter(section => section.category !== this.userProductCategory.category)
        this.$store.dispatch('save', { key: 'allProductsSections', value: this.$store.state.allProductsSections })
      }
      // Navigate back
      this.$router.go(-1)
    },

    // Dealing with products in the category
    // 1. Detect what to do.
    editUserProductCategoryProduct (event) {
      if (event.mode === 'add') {
        this.addProductToUserProductCategory(event.product)
      } else if (event.mode === 'remove') {
        this.deleteUserProductCategoryProduct(event.product)
      }
    },
    // 2. Add a product.
    addProductToUserProductCategory (searchProduct) {
      this.$store.dispatch(
        'addProductToUserProductCategory',
        {
          user_product_category_id: this.userProductCategory.category,
          product_id: searchProduct.id
        }).then(response => {
          // Update the category here:
          this.userProductCategoryProductPage.results.unshift(response)
          // Update the category in the Products tab:
          const foundCategoryLocally = this.$store.state.allProductsSections.find(section => section.category === this.userProductCategory.category)
          if (foundCategoryLocally) foundCategoryLocally.page.results.unshift(response)
        })
    },
    // 3. Remove a product
    deleteUserProductCategoryProduct (userProductCategoryProduct) {
      this.$store.dispatch('deleteUserProductCategoryProduct', userProductCategoryProduct.id).then(() => {
        // Update the category here:
        this.userProductCategoryProductPage.results = this.userProductCategoryProductPage.results.filter(product => {
          return product.id !== userProductCategoryProduct.id
        })
        // Update the category in the Products tab:
        let foundCategoryLocally = this.$store.state.allProductsSections.find(section => section.category === this.userProductCategory.category)
        if (foundCategoryLocally) foundCategoryLocally.page.results = foundCategoryLocally.page.results.filter(product => {
          return product.id !== userProductCategoryProduct.id
        })
      })
    },
  },
  mounted () {
    if (this.category) {
      this.userProductCategory = JSON.parse(this.category)
      this.$store.dispatch('getUserProductCategoryProducts', { userProductCategoryId: this.userProductCategory.category } ).then(response => {
        this.userProductCategoryProductPage = response
      })
    }
  },
  beforeUnmount () {
    // Update the last opened own category in the all products page
  }
}
</script>

<style lang="scss">
@import './../../theme/main.scss';

</style>
